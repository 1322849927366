import React from "react"

const BMS = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.56 35.56">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path
            d="M23.64,35.56h-20A3.66,3.66,0,0,1,0,31.91V3.65A3.66,3.66,0,0,1,3.65,0h3a.4.4,0,0,1,0,.8h-3A2.86,2.86,0,0,0,.8,3.65V31.91a2.86,2.86,0,0,0,2.85,2.85h20a.4.4,0,0,1,0,.8Z"
            fill="#010101"
          />
          <path
            d="M31.91,35.56h-2a.4.4,0,0,1,0-.8h2a2.86,2.86,0,0,0,2.85-2.85V3.65A2.86,2.86,0,0,0,31.91.8H14.07a.4.4,0,0,1,0-.8H31.91a3.66,3.66,0,0,1,3.65,3.65V31.91A3.66,3.66,0,0,1,31.91,35.56Z"
            fill="#010101"
          />
          <path
            d="M10.45,3.29H6A1.87,1.87,0,0,0,4.1,5.16V9.64A1.87,1.87,0,0,0,6,11.51h4.48a1.87,1.87,0,0,0,1.87-1.87V5.16A1.87,1.87,0,0,0,10.45,3.29Zm1.07,6.35a1.07,1.07,0,0,1-1.07,1.07H6A1.07,1.07,0,0,1,4.9,9.64V5.16A1.07,1.07,0,0,1,6,4.09h4.48a1.07,1.07,0,0,1,1.07,1.07Z"
            fill="#010101"
          />
          <path
            d="M20,3.29H15.54a1.87,1.87,0,0,0-1.87,1.87V9.64a1.87,1.87,0,0,0,1.87,1.87H20a1.87,1.87,0,0,0,1.87-1.87V5.16A1.87,1.87,0,0,0,20,3.29Zm1.07,6.35A1.07,1.07,0,0,1,20,10.71H15.54a1.07,1.07,0,0,1-1.07-1.07V5.16a1.07,1.07,0,0,1,1.07-1.07H20a1.07,1.07,0,0,1,1.07,1.07Z"
            fill="#010101"
          />
          <path
            d="M29.59,3.29H25.11a1.87,1.87,0,0,0-1.87,1.87V9.64a1.87,1.87,0,0,0,1.87,1.87h4.48a1.87,1.87,0,0,0,1.87-1.87V5.16A1.87,1.87,0,0,0,29.59,3.29Zm1.07,6.35a1.07,1.07,0,0,1-1.07,1.07H25.11A1.07,1.07,0,0,1,24,9.64V5.16a1.07,1.07,0,0,1,1.07-1.07h4.48a1.07,1.07,0,0,1,1.07,1.07Z"
            fill="#010101"
          />
          <path
            d="M29.59,14.1H25.11A1.87,1.87,0,0,0,23.24,16v.77a1.87,1.87,0,0,0,1.87,1.87h4.48a1.87,1.87,0,0,0,1.87-1.87V16A1.87,1.87,0,0,0,29.59,14.1Zm1.07,2.64a1.07,1.07,0,0,1-1.07,1.07H25.11A1.07,1.07,0,0,1,24,16.74V16a1.07,1.07,0,0,1,1.07-1.07h4.48A1.07,1.07,0,0,1,30.66,16Z"
            fill="#010101"
          />
          <path
            d="M29.59,19.92H25.11a1.88,1.88,0,0,0-1.87,1.88v.76a1.88,1.88,0,0,0,1.87,1.88h4.48a1.88,1.88,0,0,0,1.87-1.88V21.8A1.88,1.88,0,0,0,29.59,19.92Zm1.07,2.64a1.07,1.07,0,0,1-1.07,1.08H25.11A1.07,1.07,0,0,1,24,22.56V21.8a1.07,1.07,0,0,1,1.07-1.07h4.48a1.07,1.07,0,0,1,1.07,1.07Z"
            fill="#010101"
          />
          <path
            d="M29.59,25.75H25.11a1.88,1.88,0,0,0-1.87,1.88v.76a1.87,1.87,0,0,0,1.87,1.87h4.48a1.87,1.87,0,0,0,1.87-1.87v-.76A1.88,1.88,0,0,0,29.59,25.75Zm1.07,2.64a1.07,1.07,0,0,1-1.07,1.07H25.11A1.07,1.07,0,0,1,24,28.39v-.76a1.07,1.07,0,0,1,1.07-1.08h4.48a1.07,1.07,0,0,1,1.07,1.08Z"
            fill="#010101"
          />
          <path
            d="M11,21.5a2.71,2.71,0,0,0-2.34-2.82V15.55a.4.4,0,1,0-.8,0v3.11a2.75,2.75,0,0,0-2.34,2.61,2.73,2.73,0,0,0,2.34,2.81v7.49a.4.4,0,0,0,.8,0V24.09a2.76,2.76,0,0,0,1.46-.69A2.72,2.72,0,0,0,11,21.5ZM8.13,23.32a1.94,1.94,0,0,1,.07-3.88h.09a1.89,1.89,0,0,1,1.35.63,1.92,1.92,0,0,1,.51,1.39,1.89,1.89,0,0,1-.63,1.35A1.85,1.85,0,0,1,8.13,23.32Z"
            fill="#010101"
          />
          <path
            d="M20.51,27.62a2.72,2.72,0,0,0-2.33-2.69V15.55a.4.4,0,0,0-.8,0v9.38a2.72,2.72,0,0,0,0,5.39v1.25a.4.4,0,1,0,.8,0V30.32A2.73,2.73,0,0,0,20.51,27.62Zm-2.73,1.94a1.94,1.94,0,1,1,1.93-1.94A1.94,1.94,0,0,1,17.78,29.56Z"
            fill="#010101"
          />
        </g>
      </g>
    </svg>
  )
}

export default BMS
