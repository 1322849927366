import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const initialSchemaData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Nowa Kamienica',
  image: 'https://www.nowakamienica.eu/static/bg-min-ac45b4ac7290467ca567bb30fb134c55.jpg',
  '@id': '',
  priceRange: 'od 46 zł / m2',
  url: 'https://dlugoszabp.pl/',
  description:
    'Nowoczesny i funkcjonalny budynek biurowy do wynajęcia do wynajęcia w centrum Krakowa ul. Rakowiecka 7 ➤ Wynajem powierzchni biurowej ➤ Sprawdź!',
  disambiguatingDescription:
    'Nowa Kamienica jest nowoczesnym i funkcjonalnym projektem biurowym, zlokalizowanym w samym sercu Krakowa, w bezpośrednim sąsiedztwie Dworca Głównego, Miejskiego Dworca Autobusowego oraz Rynku Głównego.',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Kraków',
    addressRegion: 'Województwo małopolskie',
    postalCode: '31-510',
    streetAddress: 'Rakowiecka 7',
    addressCountry: 'PL',
    telephone: '+48 690 900 720',
    email: 'jan.plocinski@martleycap.com',
  },
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    opens: '08:00',
    closes: '16:00',
  },
  sameAs: ['https://www.nowakamienica.eu/'],
}

const schemaData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Nowa Kamienica',
  alternateName: 'Nowa Kamienica Kraków - Powierzchnia do wynajęcia',
  url: 'https://www.nowakamienica.eu/',
  logo: 'https://www.nowakamienica.eu/static/bg-min-ac45b4ac7290467ca567bb30fb134c55.jpg',
  sameAs: 'https://uk.linkedin.com/company/martley-capital-group/',
}

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <html lang="pl" />
            <title>{seo.title}</title>
            <meta name="theme-color" content="#000"></meta>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <script type="application/ld+json">{JSON.stringify(initialSchemaData)}</script>
            <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
