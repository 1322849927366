import React from "react";
import styled from "styled-components";

const goUpButton = ({ propertyName }) => {
  const goTopHandler = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <StyledLogo onClick={goTopHandler} href="/">
      {propertyName}
    </StyledLogo>
  );
};

export default goUpButton;

const StyledLogo = styled.a`
  display: inline-block;
  width: 100%;
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 3.39;
  letter-spacing: 3.27px;
  color: #fff;
  text-decoration: none;
`;
