import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Carousel } from "3d-react-carousal";

interface CarouselProps {
  images: Array<any>;
}

const CarouselMain: React.FC<CarouselProps> = ({ images }) => {
  const slides = [];
  images.forEach(image => {
    slides.push(<StyledImg fluid={image.childImageSharp.fluid} />);
  });

  return (
    <CarouselWrapper>
      <Carousel slides={slides}></Carousel>
    </CarouselWrapper>
  );
};

export default React.memo(CarouselMain);

const StyledImg = styled(Img)`
  border-radius: 15px;
  background: transparent;
  background-color: transparent;
`;
const CarouselWrapper = styled.div`
  display: block;
  @media screen and (max-width: 576px) {
    display: none;
  }
`;
